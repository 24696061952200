import React, { Children, useEffect, useState } from "react";
import logo from "../assests/logo.png"
import download from "../assests/download.png";
import {
    FaTh,
    FaBars,
    FaUserAlt,
    FaRegChartBar,
    FaCommentAlt,
    FaShoppingBag,
    FaThList
} from "react-icons/fa";
import { NavLink, redirect, useLocation, useNavigate } from "react-router-dom";
import Login from "../AuthPage/Login.jsx";
import { ToastContainer, toast } from "react-toastify";
import { LuLogOut } from "react-icons/lu";
import axios from "axios";
import { CiLocationArrow1 } from "react-icons/ci";
import { BiUser } from "react-icons/bi";
import { PiFlagBanner } from "react-icons/pi";


const Sidebar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(true);
    // const [loginpage, setloginpage] = useState(false);
    // const [show, setshow] = useState(false)
    const toggle = () => setIsOpen(!isOpen);

    const location = useLocation()
    const login = location.pathname === "/"
    const navigate = useNavigate()



    const [showForm, setShowForm] = useState(false);

    const showForms = () => {
        setShowForm(!showForm);
    }


    const menuItem = [

        {
            path: "/dashboard",
            name: "Dashboard",
            icon: <FaTh />
        },

        {
            path: "/banner",
            name: "Banner",
            icon: <PiFlagBanner />
        },

        {
            path: "/userlist",
            name: "Userlist",
            icon: <BiUser />
        },

        {
            path: "/categories",
            name: "Categories",
            icon: <FaUserAlt />
        },
        {
            path: "/subcategory",
            name: "SubCategory",
            icon: <FaRegChartBar />
        },
        // {
        //     path: "/cardlist",
        //     name: "CardList",
        //     icon: <FaThList />
        // },
        {
            path: "/logut",
            name: "Logout",
            icon: <LuLogOut />
        },
    ]


    const handlelogout = (e) => {
        e.preventDefault()
        localStorage.clear()

        toast.success("You are Successfully Logout")
        setTimeout(() => {
            navigate('/')
        }, 700);
    }
    return (

        <>
            <ToastContainer />
            {login ? <Login /> : <div className="sidebarmain d-flex" style={{ background: "#000" }}>
                <div style={{ width: isOpen ? "300px" : "70px" }} className="sidebar position-relative">
                    <div className="top_section">
                        <img style={{ display: isOpen ? "block" : "none" }} src={logo} alt="" />
                        {/* <h1 style={{display: isOpen ? "block" : "none"}} className="logo">Logo</h1> */}
                        <img style={{ marginLeft: isOpen ? "50px" : "0px", display: isOpen ? "none" : "block" }} src={download} alt="" className="icon" />
                    </div>

                    {
                        menuItem.map((item, index) => (
                            <NavLink to={item.path} key={index} className="link" activeclassName="active" onClick={(e) => item.name == "Logout" ? handlelogout(e) : ""}>
                                <div className="icon">{item.icon}</div>
                                <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                            </NavLink>
                        ))
                    }

                </div>
                <main className="bg-white">
                    <div style={{ height: "70px" }} className="bg-body-white bg-white">
                        {isOpen ? <div style={{ marginLeft: isOpen ? "8px" : "0px" }} className="bars">
                            <FaBars onClick={toggle} />

                        </div> : <div style={{ marginLeft: isOpen ? "8px" : "0px", }} className="bars" >
                            <i class="fa-solid fa-xmark" onClick={toggle}></i>
                        </div>}
                    </div>

                    <div className="main ">
                        {children}
                    </div>
                </main>
            </div>}
        </>




    );
};

export default Sidebar;

