import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const Magazine = () => {

    const [desc , setdesc] = useState("")
    
    const handleEditor = (event, editor) => {
        const data = editor.getData();
        setdesc(data);
      };
    


    return (
        <section>
            <div className="container-fluid">
              <Form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                    <label htmlFor="">Heading</label>
                    <input type="text" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                    <label htmlFor="">Image</label>
                    <input type="file" className="form-control" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                <CKEditor
                    editor={ClassicEditor}
                    data={desc}
                    onChange={handleEditor}
                    className="custom-ckeditor"
                  />
                  </div>
             </div>
             <div className="col-md-12">
                <Button className="btn">Submit</Button>
              </div>
             </div>
          </Form>
          </div>    
        </section>
    );
};



export default Magazine;






                