import React from "react";
import axios from "axios";


const CardList = () => {

  axios.get('http://192.168.1.8:8080/api/bid')
  .then(response => {
      console.log(response.data.data)
  })
  .catch(error => {
      console.log('feteching error');
  });

 

    return (
        <section>
            <div className="container-fluid">
              <div className="row">
                <div class="card" style={{width: "18rem"}}>
                <img src="..." class="card-img-top" />
                <div class="card-body">
                <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
                </div>
              </div>
            </div>
        </section>
    );
};

export default CardList;
