import axios from "axios";
import { BASE_URL } from "../URL"



export const postapiwithoutheader = async (endpoint, requestdata) => {
    try {
        const items = await axios.post(`${BASE_URL}${endpoint}`, requestdata);
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const postwithheader = async (endpoint, formdata, token) => {

    try {
        const items = await axios.post(`${BASE_URL}${endpoint}`, formdata, { headers: { Authorization: `Bearer ${token}` } });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}


export const getwithheader = async (endpoint, token) => {

    try {
        const items = await axios.get(`${BASE_URL}${endpoint}`, { headers: { Authorization: `Bearer ${token}` } });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const putwithheader = async (endpoint, formdata, token) => {

    try {
        const items = await axios.put(`${BASE_URL}${endpoint}`, formdata, { headers: { Authorization: `Bearer ${token}` } });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}

export const deleteapi = async (endpoint, token) => {
    try {
        const items = await axios.delete(`${BASE_URL}${endpoint}`, { headers: { Authorization: `Bearer ${token}` } });
        return items.data;
    } catch (errors) {
        console.log(errors);
        return { data: errors }
    }
}