import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { BASE_URL } from "../URL";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';
import { deleteapi, putwithheader } from "../Api/Apis";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


function SubCategory() {

  const [loading, setloading] = useState(false)

  let token = localStorage.getItem("paneltoken")
  const [select, setselect] = useState("")
  const [description, setdescription] = useState("")
  const [title, settitle] = useState("")
  const [type, settype] = useState("")
  const [image, setimage] = useState("")
  const [Categories, setcategories] = useState("")
  const [editid, seteditid] = useState("")


  const [category, setcategory] = useState([]);
  const [subcategory, setsubcategory] = useState([])


  const headers = {
    Authorization: `Bearer ${token}`

  }



  const handlecategory = () => {
    axios.get(`${BASE_URL}category`, { headers }).then((res) => {
      console.log(res.data.data)
      setcategory(res.data.data)
    })
  }

  const handlesubcategory = () => {
    axios.get(`${BASE_URL}subcategory`, { headers }).then((res) => {
      console.log("subcategory", res.data.data)
      setsubcategory(res.data.data)
    })
  }

  useEffect(() => {
    handlecategory()
    handlesubcategory()
  }, [])

  const handlesubmit = async (e) => {
    e.preventDefault()
    setselect("")
    setdescription("")
    settitle("")
    settype("")
    setimage("")
    setloading(true)

    let formdata = new FormData()

    if (!editid) {
      formdata.append("category", Categories)
      formdata.append("description", description)
      formdata.append("title", title)
      formdata.append("type", type)
      formdata.append("image", image)

      axios.post(`${BASE_URL}subcategory`, formdata, { headers }).then((response) => {
        console.log(response);
        if (response.data.status == "OK") {
          toast.success("data submitted successfully");
          handlesubcategory()
        }
      })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Error submitting data. Please try again later.");
        })
        .finally(() => {
          setloading(false);

        });
    } else {
      formdata.append("category", Categories)
      formdata.append("description", description)
      formdata.append("title", title)
      formdata.append("type", type)
      if (image) {
        formdata.append("image", image)
      }
      const res = await putwithheader(`subcategory/${editid}`, formdata, token)
      if (res.error == "0") {
        toast.success(res.message)
        handlesubcategory()
        setloading(false)
        seteditid("")
      } else {
        toast.error("Data not Submit")
        setloading(false)
      }
    }
  };

  const onOptionChangeHandler = (event) => {
    setcategories(event.target.value);
  };

  const handleimage = (e) => {
    e.preventDefault()
    let selectedfile = e.target.files[0]
    setimage(selectedfile)
  }

  const handleDelete = async (e, id) => {
    setloading(true)
    e.preventDefault()
    const data = await deleteapi(`deletesubcategory/${id}`, token)
    console.log("data", data)
    if (data.status == "OK") {
      toast.success(data.message)
      handlesubcategory()
      setloading(false)
    }
  }

  const getRowId = (row) => row._id
  const columns = [

    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {
        return (
          <img src={`${BASE_URL}${params.row.image}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }}></img>
        )
      }
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      renderCell: (params) => {
        return params.row.category?.name
      }
    },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "type",
      headerName: "type",
      flex: 1,

    },
    {
      field: "description",
      headerName: "description",
      flex: 1,

    },

    {
      field: "delete",
      headerName: "Delete",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex gap-2 mt-1">
              <button className='btn bg-dark text-white' onClick={(e) => handleEdit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
              <button className='btn bg-dark text-white' onClick={(e) => handleDelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
            </div>
          </>
        )
      }

    }

  ]


  const handleEdit = (e, item) => {
    e.preventDefault()
    setcategories(item.category._id)
    settitle(item.title)
    settype(item.type)
    setdescription(item.description)
    seteditid(item._id)

    // console.log(item)

  }

  return (
    <>

      <ToastContainer />

      {loading && <div className="overlay"> (<Bars
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />) </div>}

      <section className="bg-body-tertiary">
        <div className="container-fluid">
          <h3 className="heading">SUB-CATEGORY</h3>
          <Form onSubmit={(e) => handlesubmit(e)}>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Select Category</label>
                  {/* <label htmlFor="">Select Category</label>
                  <input type="select" className="form-control" placeholder="Select Category" required value={select} onChange={(e) => setselect(e.target.value)} /> */}
                  <select className="form-control form-select" value={Categories} onChange={(e) => setcategories(e.target.value)}>
                    <option>Select Category</option>
                    {category.map((item) => {
                      return (
                        <>
                          <option value={item._id}>{item.name}</option>
                        </>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Title</label>
                  <input type="text" className="form-control" placeholder="Title" required value={title} onChange={(e) => settitle(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Type</label>
                  <input type="text" className="form-control" placeholder="Type" required value={type} onChange={(e) => settype(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Image</label>
                  <input type="file" className="form-control" onChange={(e) => handleimage(e)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Description</label>
                  <input type="text" className="form-control" placeholder="Description" required value={description} onChange={(e) => setdescription(e.target.value)} />
                </div>
              </div>
              <div className="col-md-12">
                <Button className="btn mx-3 mt-2" type="submit" >Submit</Button>
              </div>
            </div>
          </Form>
        </div>
      </section>

      {/*  */}

      <section className="bg-body-tertiary">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card-box shadow p-3 mb-5">
                <div className="card-header">
                  <h5>Sub Category List</h5>
                </div>

                <Box>
                  <DataGrid
                    rows={subcategory}
                    columns={columns}
                    getRowId={getRowId}
                    pageSize={subcategory.length} // Set the page size to the total number of rows
                    pagination={false}     // Disable pagination
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );

};



export default SubCategory;