import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap';

function AddLocation() {
    const [map, setMap] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [latlong, setlatlong] = useState("")
    const [address, setAddress] = useState("")
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [fulladdress, setfulladdress] = useState("")
    const [second, setsecond] = useState([])
    const [fourth, setfourth] = useState([])

    const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY


  


    const fetchAddress = async () => {
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlong.lat},${latlong.lng}&key=${googleApiKey}`);
            if (response.data.results.length > 0) {
                setAddress(response.data.results[0]);


            } else {
                setAddress('Address not found');
            }
        } catch (error) {
            console.error('Error fetching the address:', error);
            setAddress('Error fetching the address');
        }
    };



    useEffect(() => {
        if (latlong) {
            fetchAddress()
        }
    }, [latlong])

    useEffect(() => {
        if (address) {
            // Extract the required components
            console.log(address.address_components)
            const secondFromLast = address.address_components[address.address_components?.length - 3];
            const fourthFromLast = address.address_components[address.address_components?.length - 5];

            console.log(secondFromLast)
            console.log(fourthFromLast)

            setstate(secondFromLast.long_name)
            setcity(fourthFromLast.long_name)
            setfulladdress(address.formatted_address)
        }
    }, [address])



    useEffect(() => {
        async function loadMap() {
            try {
                // Load Google Maps API
                const google = await window.google;
                let mapCenter = await getCurrentLocation();
                const map = new google.maps.Map(document.getElementById("map"), {
                    zoom: 4,
                    center: mapCenter,
                });
                setMap(map);

                // Create the initial InfoWindow.
                const infoWindow = new google.maps.InfoWindow({
                    content: "Click the map to get Lat/Lng!",
                    position: mapCenter,
                });
                infoWindow.open(map);
                setInfoWindow(infoWindow);

                // Configure the click listener.
                map.addListener("click", (mapsMouseEvent) => {
                    // Close the current InfoWindow.
                    infoWindow.close();
                    // Create a new InfoWindow.
                    const newInfoWindow = new google.maps.InfoWindow({
                        position: mapsMouseEvent.latLng,
                    });

                    newInfoWindow.setContent(
                        JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)
                    );
                    newInfoWindow.open(map);

                    let jsonparse = JSON.parse(newInfoWindow.content)
                    setlatlong(jsonparse)

                    setInfoWindow(newInfoWindow);
                });
            } catch (error) {
                console.error("Error loading Google Maps API:", error);
            }
        }

        loadMap();

        // Cleanup function
        return () => {
            // Clean up map and infoWindow objects if necessary
        };
    }, []);

    async function getCurrentLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ lat: latitude, lng: longitude });
                    },
                    (error) => {
                        console.error("Error getting current location:", error);
                        // Default to a fallback location if getting current location fails
                        resolve({ lat: 20.5937, lng: 78.9629 });
                    }
                );
            } else {
                console.error("Geolocation is not supported by this browser.");
                // Default to a fallback location if geolocation is not supported
                resolve({ lat: 20.5937, lng: 78.9629 });
            }
        });
    }


    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 col-12">
                            <div className="mt-3">
                                <div id="map" style={{ width: "100%", height: "400px" }}></div>;
                            </div>

                        </div>

                        <button className='btn' onClick={() => getCurrentLocation()}>Current Location</button>

                        <div className="col-md-4">
                            <Form>
                                <div className="row gy-3">
                                    <div className="col-md-6">
                                        <FormGroup>
                                            <label htmlFor="">
                                                State
                                            </label>
                                            <input type="text" value={state} onChange={(e) => setstate(e.target.value)} className='form-control' />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-6">
                                        <FormGroup>
                                            <label htmlFor="">
                                                City
                                            </label>
                                            <input type="text" value={city} onChange={(e) => setcity(e.target.value)} className='form-control' />
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-12">
                                        <FormGroup>
                                            <label htmlFor="">
                                                Address
                                            </label>
                                            <textarea name="" className='form-control' value={fulladdress} onChange={(e) => setfulladdress(e.target.value)} id=""></textarea>
                                        </FormGroup>
                                    </div>
                                    <div className="col-md-12">
                                        <button className='btn custombtn'>Submit</button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default AddLocation
