import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { getwithheader, putwithheader } from '../../Api/Apis'
import { toast } from 'react-toastify'

function Userlist() {
    const [data, setdata] = useState([])
    const token = localStorage.getItem("paneltoken")

    const handleget = async () => {
        const res = await getwithheader('alluser', token)
        console.log(res)
        // setdata(res.data)
        if (res != "") {
            let filter = res.data?.filter((item) => item.type != "Admin")
            setdata(filter)
        }
    }

    useEffect(() => {
        handleget()
    }, [])

    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,

        },
        {
            field: "type",
            headerName: "Type",
            flex: 1,
        },

        {
            field: "email",
            headerName: "Email",
            flex: 1,

        },

        {
            field: "password",
            headerName: "Password",
            flex: 1,
        },

        {
            field: "status",
            headerName: "Status",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>

                        <button className='btn p-2 ' >
                            <span className={`badge px-3 text-uppercase ${params.row.status == "pending" ? "bg-warning" : params.row.status == "cancelled" ? "bg-danger" : "bg-success"}`}>{params.row.status}</span>
                        </button>
                    </>
                )
            }

        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className='d-flex gap-2 mt-1'>
                            <button className='accepted btn bg-dark text-white' onClick={(e) => handlechangestatus(e, params.row._id, "accepted")}><i class="fa-solid fa-check"></i></button>
                            <button className='accepted btn bg-dark text-white' onClick={(e) => handlechangestatus(e, params.row._id, "cancelled")}><i class="fa-solid fa-xmark"></i></button>
                        </div>
                    </>
                )
            }

        }
    ]


    const handlechangestatus = async (e, id, name) => {
        e.preventDefault()
        let formdata = new FormData()
        formdata.append("_id", id)
        formdata.append("status", name)
        const res = await putwithheader("updateuser", formdata, token)
        if (res.error == "0") {
            toast.success(res.message)
            handleget()
        } else {
            toast.error("Data not Update")
        }

    }
    const getRowId = (row) => row._id
    return (
        <>
            <section className="bg-body-tertiary" style={{ overflow: "hidden" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <Box>
                                    <DataGrid
                                        rows={data}
                                        columns={columns}
                                        getRowId={getRowId}
                                    />

                                </Box>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Userlist
