import React from "react";
import './Dashboard.css';


function Dashboard() {
  return (
    <section className="bg-body-tertiary">
      <div className="container-fluid">
        <h3 className="heading">DASHBOARD</h3>
        <div className="row">
          <div className="col-md-3">
            <div className="card" style={{ backgroundColor: "#314da7" }}>
              <div className="card-body">
                <span>Products</span>
                <div className="icon-box">
                  <i class="fa-solid fa-cube"></i>
                </div>
                <h3>
                  $
                  <span>9856</span>
                  <small>This Month</small>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card" style={{ backgroundColor: "#00baf2" }}>
              <div className="card-body">
                <span>Messages</span>
                <div className="icon-box">
                  <i class="fa-regular fa-message"></i>
                </div>
                <h3>
                  $
                  <span>893</span>
                  <small>This Month</small>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card" style={{ backgroundColor: "#00ced1" }}>
              <div className="card-body">
                <span>Earnings</span>
                <div className="icon-box">
                  <i class="fa-solid fa-location-arrow"></i>
                </div>
                <h3>
                  $
                  <span>6659</span>
                  <small>This Month</small>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card" style={{ backgroundColor: "#11d89c" }}>
              <div className="card-body">
                <span>New Vendors</span>
                <div className="icon-box">
                  <i class="fa-solid fa-user-group"></i>
                </div>
                <h3>
                  $
                  <span>45631</span>
                  <small>This Month</small>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default Dashboard;